import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';

import AppRoutes from './Routes';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVectorSquare } from '@fortawesome/free-solid-svg-icons';


function App() {
  return (

    
    <Container fluid>
      <Navbar bg="dark" variant="dark" fixed="top" expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <FontAwesomeIcon icon={faVectorSquare} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/story">Story</Nav.Link>              
              <Nav.Link href="/join">Join</Nav.Link>              
              <Nav.Link href="/about">About</Nav.Link>              
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
        <AppRoutes />  
    </Container>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { Col, Card } from "react-bootstrap";
import { useParams} from "react-router-dom";
// import { run as runHolder } from 'holderjs/holder';

import AudioSpectrum  from "react-audio-spectrum";

import story_data from '../../data/stories';
import Callout from "../Join/Callout";

import audio_1 from '../../static/audio/audio-1.mp3';
import audio_2 from '../../static/audio/audio-2.mp3';

const StorySingle = () => {

    let [story, updateStory] = useState(null);
    let [picture, updatePicture] = useState();

    let params = useParams();

    useEffect(() => {

        fetch('https://randomuser.me/api')
            .then(results => {
                return results.json()
            })
            .then(data => {
                console.log(data);
                let user = data.results[0];
                
                updatePicture(
                    user.picture.large
                );
                let activeStory = story_data.filter((item) => {
                    return item.id === +params.storyId;
                });
        
                activeStory[0].name = user.name.first;                
                if(activeStory[0].audio_file === 'audio-1.mp4'){
                    activeStory[0].audio = audio_1;
                }else{
                    activeStory[0].audio = audio_2;
                }
                updateStory(activeStory[0]);

            });

    }, []);

    if(!story){
        return null;

    }


    return (
        <Col className="mt-5 pt-5">
        
            <Card className="text-center">
                <Card.Img variant="top" src={picture} />
                <Card.Body >
                    <Card.Title>{story.name}, {story.title}</Card.Title>
                </Card.Body>

                <div className="text-center">
                    <audio id="audio-element"
                        src={ story.audio }
                        autoPlay
                        controls
                    >
                    </audio>
                    
                    <AudioSpectrum
                        id="audio-canvas"
                        height={100}
                        width={200}
                        audioId={'audio-element'}
                        capColor={'red'}
                        capHeight={2}
                        meterWidth={2}
                        meterCount={512}
                        meterColor={[
                            {stop: 0, color: '#f00'},
                            {stop: 0.5, color: '#0CD7FD'},
                            {stop: 1, color: 'red'}
                        ]}
                        gap={4}
                    />
                </div>

            </Card>

            <Callout />

        </Col>
    )
}

export default StorySingle;
import React from "react";
import { Link } from "react-router-dom";

const Callout = () => {


    return (

        <section className="row">
            <div className="col col-md-4 offset-md-4 text-center mt-5 pb-5">
                <h3>Join Us</h3>
                <p>
                    We're bringing together a diverse group of students interested in both getting support and offering support to each other as you adapt to life in Maynooth University
                </p>
                <Link to={'/join'} className="btn btn-lg btn-primary">
                    Find Out More
                    <br/>
                    <small>
                        Get notified about our next meetup
                    </small>
                </Link>
            </div>
        </section>
    );

};

export default Callout;
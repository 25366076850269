import React from 'react'
// import { Route, BrowserRouter, Switch } from 'react-router-dom'
import {
  BrowserRouter as Router,
  Routes,
//   HashRouter as Router,
  // Switch,
  Route
} from "react-router-dom";
import Join from './components/Join';

// import ReactGA from 'react-ga';

import Main from './components/Main'; 
import Story from './components/Story';
import StorySingle from './components/Story/Single';



// GA setup
// const trackingId = "ENTER-GA-ID-HERE";
// ReactGA.initialize(trackingId);

// Initialize google analytics page view tracking
// history.listen(location => {
//     ReactGA.set({ page: location.pathname }); // Update the user's current page
//     ReactGA.pageview(location.pathname); // Record a pageview for the given page
// });

// <Router hashType="hashbang">


/*
   <Route exact path='/story' component={ Stories } />            
            <Route exact path='/story/:storyId' component={ Story } />            
            
            <Route exact path='/join' component={ Join } />
*/

const AppRoutes = props => (
    <Router>
      <Routes>
          <Route exact path='/' element={ <Main /> } />
          <Route exact path='/story' element={ <Story /> } />
          <Route exact path='/story/:storyId' element={ <StorySingle /> } />
          <Route exact path='/join' element={ <Join /> } />
      </Routes>                                     
    </Router>
);

export default AppRoutes;
const story_data = [
    {
        "id": 1,
        "short_title": "graduated 2019",
        "name": "",
        "title": "MSC Design Innovation, graduated 2019",
        "quote": "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem ",
        "thumbnail": "holder.js/100px90",
        "audio_file": "audio-1.mp4"
    },
    {
        "id": 2,
        "name": "",
        "short_title": "graduated 2019",
        "title": "MSC Design Innovation, graduated 2019",
        "quote": "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem ",
        "thumbnail": "holder.js/100px90",
        "audio_file": "audio-2.mp4"
    },
    {
        "id": 3,
        "name": "",
        "short_title": "Graduated 2019",
        "title": "MSC Design Innovation, graduated 2019",
        "quote": "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem ",
        "thumbnail": "holder.js/100px90",
        "audio_file": "audio-1.mp4"
    },
    {
        "id": 4,
        "name": "",
        "short_title": "graduated 2019",
        "title": "MSC Design Innovation, graduated 2019",
        "quote": "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem ",
        "thumbnail": "holder.js/100px90",
        "audio_file": "audio-2.mp4"
    }
];

export default story_data;
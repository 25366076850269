import React, { useEffect } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { run as runHolder } from 'holderjs/holder';
import { Link } from "react-router-dom";

import story_data from '../data/stories';
import Callout from "./Join/Callout";

import cover_image from "../static/images/generated-profile-pics.png";

const Main = () => {

    useEffect(() => { runHolder('image-class-name'); });

    
    let thumbs = story_data.map((item, idx )=> {

        
            return (
                <Col key={`item-` + idx}>
                    <Link to={`/story/` + item.id}>
                        <Card className="text-center">
                            <Card.Img variant="top" src={item.thumbnail} />
                            <Card.Body >
                                <Card.Title>{item.short_title}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Link>

                </Col>
            );
        
    });
    
    return (
        <section className="pt-5">
            <div className="text-center">
                
                <img alt="" src={cover_image} className="mt-3 mb-2" />

                <p>What advice would you give yourself starting out in university?</p>


                <Row xs={2} className="g-4">
                    { thumbs }
                </Row>
                <Row>
                    <Col className="text-center pt-3 pb-0">
                        <p>
                             <Link to={'/more'}>
                                Read more &raquo; 
                            </Link>
                        </p>
                    </Col>
                </Row>

            </div>

            <Callout />
        </section>
    )
}

export default Main;
import React, { useEffect } from "react";
import { run as runHolder } from 'holderjs/holder';

import { Form, Button, Col, Row } from "react-bootstrap";

const Join = () => {

    useEffect(() => { runHolder('image-class-name'); });

    return (
        <div className="mt-5 pt-5 pb-5 mb-5">
            <h2 className="text-center">Join Us</h2>

            <p>
                We're bringing together a diverse group of students interested in both
                getting support and offering support to others as you adapt to life 
                in Maynooth University.

            </p>

            <ul className="list-inline text-center">
                <li className="list-inline-item">
                    <img src="holder.js/120x50" alt="" />
                </li>
                <li className="list-inline-item">
                    <img src="holder.js/120x50" alt="" />
                </li>
            </ul>

            <Form className="mt-4 pt-4 pb-3">
                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label className="sr-only" column sm={2}>
                        Name
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" placeholder="Name" />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label className="sr-only"  column sm={2}>
                        Email
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Control type="email" placeholder="Email" />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label className="sr-only" column sm={2}>
                        Mobile Number
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" placeholder="Phone Number to Text" />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Col className="text-center" sm={{ span: 10, offset: 2 }}>
                        <Button type="submit">Let Me Know!</Button>
                    </Col>
                </Form.Group>
            </Form>

            <p>
                We'll let you know when the next meetup is, and will
                include some details on ways you can get support if 
                you feel you need it, or can offer support to others.
            </p>
        </div>
    )
}

export default Join;